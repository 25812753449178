import BigHeaderWithBanner from "../../Components/BigHeaderWithBanner/Index";
import ContentCentered from "../../Components/ContentCentered/Index";
import ContentPhoto from "../../Components/ContentPhoto/Index";
import ContentPhotoHomeUpdate from "../../Components/ContentPhotoHomeUpdate/Index";
import ZFooter from "../../Components/Footer/Index";
import Header from "../../Components/Header/Index";
import LibertyBanner from "../../Components/LibertyBanner/Index";
import Newsletter from "../../Components/Newsletter/Index";


import icon_satelite_antena from '../../Assets/Images/icon-satelite-antena.png';
import icon_chart from '../../Assets/Images/icon-chart.png';
import icon_rocket from '../../Assets/Images/icon-rocket.png';
import icon_veteran from '../../Assets/Images/icon-veteran.png';
import X3YellowBoxes from "../../Components/X3YellowBoxes/Index";

export default function Home(){

  console.log('Home page is created...');

  return (<div className="page">
    <BigHeaderWithBanner />
<LibertyBanner content={
  /*<div><h2>PIONEER DEVELOPMENT GROUP (PDG)<br />is taking a stand for Liberty </h2></div>*/
  <div><h2>PIONEER DEVELOPMENT GROUP (PDG)<br />is taking a stand for Liberty </h2></div>
  } />
    {/*<ContentPhoto />*/}
    <ContentPhotoHomeUpdate 
      content={
        /*<div><h2>PDG developed the Liberty Blockchain and is working on Applications that promote and celebrate the principles of a free and open society.</h2></div>*/
        <div><h2>PDG DEVELOPED THE LIBERTY BLOCKCHAIN AND IS WORKING ON APPLICATIONS THAT PROMOTE AND CELEBRATE THE PRINCIPLES OF A FREE AND OPEN SOCIETY</h2></div>
      }
      contentFullWidth={true} 
      contentCentered={true}
      marginBottom="mb-0"
      paddingBottom="padding-bottom-for-x3-boxes"
      />
    <X3YellowBoxes 
    marginTop="margin-top-go-up"
    panelsContent={[
      {
        icon:icon_veteran,
        content:<span><h4>VETERAN-LED</h4><p>Veterans have long fought for Liberty. Pioneer Development Group is proud to have a significant Veterans presence on our team and in our leadership.</p></span>,
      },
      {
        icon:icon_chart,
        content:<span><h4>WEB 3-FOCUSED</h4><p>Pioneer Development Group is developing an entire ecosystem dedicated to human liberty in cyberspace.</p></span>,
      },
      {
        icon:icon_satelite_antena,
        content:<span><h4>GIVING BACK</h4><p>Pioneer Development Group is building relationships with charities and organizations of all kinds that celebrate or promote the ideas, history, and people who have fought for freedom and justice.</p></span>,
      },
    ]} />
    <ContentCentered
      content={<div>
        <h5>Latest update</h5>
        {/*<h2>LATEST ON THE LIBERTY<br/>Star Dispatch</h2>*/}
        <h2>News you can use about Blockchain, Web 3 and more on the Liberty Star Dispatch</h2>
        </div>}
    />
  </div>);
}