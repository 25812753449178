import { Col, Container, Row } from "react-bootstrap";
import CustomLink from "../CustomLink/Index";
import rockets from '../../Assets/Images/rockets-2.png';
import the_planet from '../../Assets/Images/the-planet.png';
import the_planet_red from '../../Assets/Images/planet-saturn-red.png';
import livberty_logo from '../../Assets/Images/livberty_logo.png';


export default function BigHeaderWithContent({
  content, 
  coverPhoto, 
  thePlanet, 
  margin_bottom, 
  illustration, 
  thePlanetSRed,
  isForPost,
  PostPhoto,
  PostTitle,
  }){
  return (<section className={`big_header_with_content ${(isForPost===true?'is_for_post':'')} ${(isForPost===true && (PostPhoto===undefined || PostPhoto===null)?'justify_post_content_to_the_right':'')} ${(coverPhoto!==undefined && coverPhoto!==null?coverPhoto:'')} ${(margin_bottom!==undefined && margin_bottom!==null?margin_bottom:'')} ${(illustration!==undefined && illustration!==null?illustration:'')}`}>

    <div className="stars"></div>
    <img src={(thePlanet!==undefined && thePlanet!==null?thePlanet:the_planet)} className="the_planet" alt="The planet Saturn" />
    <img src={(thePlanetSRed!==undefined && thePlanetSRed!==null?thePlanetSRed:the_planet_red)} className="the_planet the_planet_red" alt="The planet Saturn Red" />
    <div className="rockets_holder">
      <div className="rockets"></div>
    </div>

    <Container>
      <Row>
        <Col lg={12}>

          {
            isForPost===true && PostPhoto!==undefined && PostPhoto!==null?
            <img src={PostPhoto} alt={PostTitle} />
            :
            null
          }
          {
            isForPost===true && (PostPhoto===undefined || PostPhoto===null)?
            <img src={livberty_logo} alt={PostTitle} className="post_default_image" style={{height:'1px', opacity:0}} />
            :null
          }

          <div className="content">
            {/*<h1>THERE IS  NOTHING FREE ABOUT THE INTERNET AND APPLICATIONS TODAY</h1>
            <p>The websites and applications you use today may seem “free” but you pay for them with your privacy. Search engines and many apps learn all about you and sell your data to anyone who will pay for it. Those people want to tell what to buy, how to vote, or even what to think. </p>
            <CustomLink to="./Participate" className="btn btn-success">Participate</CustomLink>*/}
            {(content!==undefined && content!==null?content:'You need to add content')}
          </div>
        </Col>
      </Row>
    </Container>
  </section>);
}