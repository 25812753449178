import { Col, Container, Row } from "react-bootstrap";
import DashboardPanelCount from "../../Components/PanelCount/Index";

export default function DashboardHome(){
  return (<div>
      <Container className="dashboard_counts_grid">
        <Row>
          <Col lg={12}>
            <DashboardPanelCount />
            <DashboardPanelCount />
            <div className="dashboard_panels_x2_areas">
              <div className="area_1"></div>
              <div className="area_2"></div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col lg={12}>
            <div className="dashboard_content_panel"></div>
          </Col>
        </Row>
      </Container>

    </div>);
}