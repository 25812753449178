import { Container, Row, Col } from "react-bootstrap";
import DashboardPanelCount from "../../../Components/PanelCount/Index";

export default function DashboardRewards(){
  return (<div>
      <Container className="dashboard_counts_grid">
          <Row>
            <Col lg={12}>
              <DashboardPanelCount 
                title="Total Points" count="0" status="Points"
                link={{to:'/', title:'Earn More Points'}}
                info={{}}
                />
              <DashboardPanelCount 
                title="Last Rewards Distribution" count="0" status="Brand coin"
                link={{to:'/', title:'Reward Calculation Details'}}
                info={{}}
                />
              <DashboardPanelCount 
                title="Last Rewards Distribution" count="0" status="Brand coin"
                link={{to:'/', title:'Bridge rewards to eth network'}}
                info={{}}
                />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg={12}>
              <div className="dashboard_content_panel"></div>
            </Col>
          </Row>
        </Container>

    </div>);
}