import { Button } from "react-bootstrap";
import { NavigateToPage } from "../../Help/Functions";
//import { EVENT_POPSTATE } from "../../Constants";

export default function CustomLink({children, to, className}){
  return (
    <a href={to} className={className} onClick={(e)=>{
      e.preventDefault();

      if(to.indexOf('http')!==-1){
        //window.location.href = to;
        window.open(to);
      }
      else if(to.indexOf('.pdf')!==-1){
        window.open(to);
      }
      else if(to!==window.location.pathname)
        NavigateToPage(to);

    }}>{
      children
    }</a>
  );
}