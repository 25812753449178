import { Button, Form, InputGroup } from "react-bootstrap";

import x2_astronauts from '../../Assets/Images/login-side-people.png';
import login_bottom_area from '../../Assets/Images/login-bottom-area.png';
import login_moon from '../../Assets/Images/login-the-moon.png';
import login_right_planets from '../../Assets/Images/login-the-right-planets.png';
import login_form_ufo from '../../Assets/Images/login-ufo.png';
import login_logo from '../../Assets/Images/login-liberty-logo.png';
import login_username_icon from '../../Assets/Images/login-user-icon.png';
import login_login_key_icon from '../../Assets/Images/login-key-icon.png';
import CustomLink from "../../Components/CustomLink/Index";
import { useState } from "react";
import { NavigateToPage } from "../../Help/Functions";



export default function Login(){

  const [errorMessage, setErrorMessage] = useState('');

  const __TryToSignIn = ()=>{
    if(1===2){
      //When not success login
      setErrorMessage('Login Details Incorrect');
    }
    else{
      NavigateToPage('/Dashboard/Home');
    }
  }

  return (
    <div className="page">
      <Form className="login_form">
        <div className="login_the_stars"></div>
        <img src={login_right_planets} className="login_right_planets" alt="Login Form - Right Planets" />
        <img src={login_moon} className="login_moon" alt="Login Form - Moon" />
        <img src={login_bottom_area} className="login_bottom_area" alt="Login Form - Bottom Arrea" />
        <img src={x2_astronauts} className="the_astronauts_on_the_sides" alt="Login Form - The Astronauts" />

        <div className="login_form_content">
          <img src={login_form_ufo} className="login_form_ufo" alt="Login Form UFO" />

          <div className="login_form_inputs">
            <img src={login_logo} className="login_logo" alt="Login Logo" />

            
            <div className="login_form_control_holder have_icon">
              <Form.Control
                placeholder="Username"
                aria-label="Username"
              />
              <img src={login_username_icon} alt="Login Username Icon" />
            </div>
            <div className="login_form_control_holder have_icon">
              <Form.Control
                placeholder="Password"
                aria-label="Password"
                type="password"
              />
              <img src={login_login_key_icon} alt="Login Password Icon" />
            </div>

            <div className="login_big_button_holder">
              {errorMessage!==''?<div className="error">{errorMessage}</div>:null}
              <Button variant="primary" onClick={__TryToSignIn}>Sign in</Button>
            </div>

            <div className="login_forgot_link">
              <CustomLink to="/User/ForgotPassword" className="login_link">Forgot Password?</CustomLink>
            </div>

            <div className="text-center">
              Need an account? <CustomLink to="/User/SignUp" className="login_link">Sign Up</CustomLink>
            </div>

          </div>

        </div>

      </Form>
    </div>
  );
}