import CustomLink from "../../../../Components/CustomLink/Index";

import logo_liberty from '../../../../Assets/Images/dashboard-liberty-logo.png';
import icon_monitor from '../../../../Assets/Images/dashboard-monitor.png';
import icon_box from '../../../../Assets/Images/dashboard-icon-box.png';
import icon_gift from '../../../../Assets/Images/dashboard-git-box.png';
import icon_wallet from '../../../../Assets/Images/dashboard-icon-wallet.png';

import icon_store from '../../../../Assets/Images/dashboard-icon-store.png';
import icon_cart from '../../../../Assets/Images/dashboard-icon-cart.png';
import icon_refer from '../../../../Assets/Images/dashboard-icon-refer-a-friend.png';
import icon_voting from '../../../../Assets/Images/dashboard-icon-vote.png';
import icon_support from '../../../../Assets/Images/dashboard-icon-support.png';
import { useState } from "react";


import icon_logout from '../../../../Assets/Images/icon-logout.png';
import icon_account from '../../../../Assets/Images/icon-account.png';




export default function DashboardSidebarMenu(){

  const [indexOpenedSub, setIndexOpenedSub] = useState(-1);

  const the_links = [
    {
      icon:icon_monitor,
      title:'Dashboard',
      items:[
        {
          icon:icon_box,
          title:'Nodes',
          to:'/Dashboard/Nodes'
        },
        {
          icon:icon_gift,
          title:'Rewards',
          to:'/Dashboard/Rewards'
        },
        {
          icon:icon_wallet,
          title:'Wallet',
          to:'/Dashboard/Wallet'
        },
        {
          icon:icon_wallet,
          title:'Wallet Balances',
          to:'/Dashboard/WalletBalances'
        },
      ]
    },

    
    {
      icon:icon_store,
      title:'Store',
      to:'/Dashboard/Store'
    },
    {
      icon:icon_cart,
      title:'Cart',
      to:'/Dashboard/Cart'
    },

    {
      icon:icon_refer,
      title:'Refer a Friend',
      to:'/Dashboard/ReferAFriend'
    },
    {
      icon:icon_voting,
      title:'Voting',
      to:'/Dashboard/Voting'
    },
    {
      icon:icon_support,
      title:'Support',
      to:'/Dashboard/Support'
    },
  ];
  
  const __SomeOfItemsAreOpened = (listItems)=>{
    for(let i=0;i<listItems.length;i++){
      if(listItems[i].to===window.location.pathname)return true;
    }
    return false;
  }

  return (<div className="dashboard_sidebar_menu">
    <CustomLink to="/Dashboard/Home" className="d_logo">
      <img src={logo_liberty} alt="Dashboard Liberty" />
    </CustomLink>

    <ul>
      {
        the_links.map((linkv, key)=>{
          if(linkv.items!==null && linkv.items!==undefined)
            return <li key={`link-to-the-dashboard-${key}`} className={`${key===indexOpenedSub || __SomeOfItemsAreOpened(linkv.items)?'opened':''}`} onClick={(e)=>{
              //console.log(e, linkv)
              if(key===indexOpenedSub)
                setIndexOpenedSub(-1);
              else
                setIndexOpenedSub(key);
            }}>
            <a onClick={(e)=>{e.preventDefault()}}>
              {linkv.icon!==null?<img src={linkv.icon} alt={linkv.title} />:null}{linkv.title}
            </a>
            <ul className="submenu">
              {
                linkv.items.map((subitem, key)=>{
                  return <li key={`sub-item-${key}`}>
                    <CustomLink to={subitem.to} className={`${subitem.to===window.location.pathname?'active':''}`}>
                      {subitem.icon!==null?<img src={subitem.icon} alt={subitem.title} />:null}{subitem.title}
                    </CustomLink>
                  </li>
                })
              }
            </ul>
          </li> 
          return <li key={`link-to-the-dashboard-${key}`}>
            <CustomLink to={linkv.to} className={`${linkv.to===window.location.pathname?'active':''}`}>
              {linkv.icon!==null?<img src={linkv.icon} alt={linkv.title} />:null}{linkv.title}
            </CustomLink>
          </li>
        })
      }
    </ul>


    <ul className="logged_user">
      <li>
        <CustomLink to="/User/Login" className="account_link">
          <img src={icon_account} alt="Account" /> 
          <span>
            <strong>User Name</strong>
            user@email.com
          </span>
        </CustomLink>
      </li>
      <li>
        <CustomLink to="/User/Login">
          <img src={icon_logout} alt="Log Out" />  Log Out
        </CustomLink>
      </li>
    </ul>

  </div>);
}