import { Col, Container, Row } from "react-bootstrap";

import antena from '../../Assets/Images/icon-2-antena.png';
import chart from '../../Assets/Images/icon-2-chart.png';
import rocket from '../../Assets/Images/icon-2-rocket.png';
import ListBoxesPosts from "../ListBoxesPosts/Index";

export default function Content({margin_bottom, content_top, iconsContent, contentCenteredBoxes, bg_type, addListBoxesPosts, showAllPosts}){

  /*const icons_content = [
    {
      icon:antena,
      content: <span><h4>WEb 3 ARCHITECTURE</h4><p>Decentralized technology to ensure everyone is given a fair chance to compete in the marketplace of ideas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam.</p></span>
    },
    {
      icon:chart,
      content: <span><h4>EARN REWARDS</h4><p>Decentralized technology to ensure everyone is given a fair chance to compete in the marketplace of ideas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam.</p></span>
    },
    {
      icon:rocket,
      content: <span><h4>FOUNDED BY VETERANS</h4><p>Celebrate and support the people, organizations, and principles that stand and have stood for freedom around the world. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam.</p></span>
    },
  ];*/
  const icons_content = iconsContent;

  return (
    <section className={`content_photo ${margin_bottom!==undefined && margin_bottom!==null?margin_bottom:''} ${(bg_type!==undefined && bg_type!==null?bg_type:'')}`}>
      <Container>
        <Row>
          <Col lg={12}>



            {(content_top!==undefined && content_top!==null?<div className="content_top">{content_top}</div>:null)}


      {icons_content!==undefined && icons_content!==null?
            <ul className="icons_content">
              {
                icons_content.map((content, key)=>{
                  console.log('key:', key)
                  return <li key={`icons-content-item-${key}`}>
                      <span className="image">
                        <img src={content.icon} alt={`Icon-${key}`} />
                      </span>
                      <span className="content">
                        {content.content}
                      </span>
                    </li>
                })
              }
            </ul>
            :null
      }


            {
              (contentCenteredBoxes!==undefined && contentCenteredBoxes!==null?<div className="content_boxes">
                {contentCenteredBoxes.map((content, key)=>{
                  return <div className="content_box_item" key={`content-box-${key}`}>
                    <div className="content_box_item_holder">{content}</div>
                  </div>
                })}
              </div>:null)
            }

            {(addListBoxesPosts===true
              ?
              <ListBoxesPosts showAllPosts={showAllPosts} />
              :
              null)}






          </Col>
        </Row>
      </Container>
    </section>
  );
}