import { useEffect } from "react";

//import hubspot from '@hubspot/api-client';

import BigHeaderWithContent from "../../Components/BigHeaderWithContent/Index";
import Content from "../../Components/Content/Index";
import ContentCentered2 from "../../Components/ContentCentered2/Index";

export default function News(){



  
  //const hubspot = require('@hubspot/api-client');
  

  /*const hubspotClient = new hubspot.Client({"accessToken":"YOUR_ACCESS_TOKEN"});

  const createdAt = undefined;
  const createdAfter = undefined;
  const createdBefore = undefined;
  const updatedAt = undefined;
  const updatedAfter = undefined;
  const updatedBefore = undefined;
  const sort = undefined;
  const after = undefined;
  const limit = undefined;
  const archived = undefined;*/

  /*try {
    const apiResponse = await hubspotClient.cms.blogs.blogPosts.blogPostsApi.getPage(createdAt, createdAfter, createdBefore, updatedAt, updatedAfter, updatedBefore, sort, after, limit, archived);
    console.log(JSON.stringify(apiResponse, null, 2));
  } catch (e) {
    e.message === 'HTTP request failed'
      ? console.error(JSON.stringify(e.response, null, 2))
      : console.error(e)
  }*/

  //hubspotClient.cms.blogs.blogPosts.blogPostsApi.getPage(createdAt, createdAfter, createdBefore, updatedAt, updatedAfter, updatedBefore, sort, after, limit, archived);



  


  return (<div className="page">

    <BigHeaderWithContent
      margin_bottom="add_margin"
      content={<div>
          <h1>THE LIBERTY<br/>STAR DISPATCH</h1>
          {/*<CustomLink to="./Participate" className="btn btn-success">Join Us</CustomLink>*/}
        </div>}
    />

    <Content 
      margin_bottom="mb-0"
      addListBoxesPosts={true}
      showAllPosts={true}
    />

    <ContentCentered2 /> 
  </div>);
}