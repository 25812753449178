import { useState } from "react";

export default function ZCheckbox({
  checked
}){

  const [isChecked, setIsChecked] = useState(null);
  const __isChecked = ()=>{
    if(checked===true && isChecked===null)return true;
    if(isChecked===true)return true;
    return false;
  }

  return (
    <label className={`z_checkbox ${__isChecked()?'checked':''}`} onClick={()=>{}}>
      <span className="box"></span>
      <input type="checkbox" checked={__isChecked()} onChange={(e)=>{
        console.log(e, e.target.checked);
        setIsChecked(e.target.checked);
      }} />
      <span className="title">I am at least 18 years old</span>
    </label>

  );
}