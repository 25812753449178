//import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Assets/Css/Style.css';
import './Assets/Css/Style.Responsive.More.Then.1920Px.css';
//import './Assets/Css/Style.Responsive.Less.Then.1920Px.css';
//import './Assets/Css/Style.Responsive.Fix.Desktop.css';
//import './Assets/Css/Style.991pxAndBellow.css';
import './Assets/Css/Style.Mobile.css';
import './Assets/Css/Style.Mobile.Responsivity.css';
import 'react-phone-number-input/style.css'

import Home from './Pages/Home/Index';
import { useEffect, useState } from 'react';
import Participate from './Pages/Participate/Index';
import { EVENT_POPSTATE } from './Help/Constants';
import Header from './Components/Header/Index';
import ZFooter from './Components/Footer/Index';
import PagesContent from './Pages/PagesContent';
import Newsletter from './Components/Newsletter/Index';
import LogosBox from './Components/LogosBox/Index';

import TagManager from 'react-gtm-module';
import { NavigateToPage, ShowStandardElements } from './Help/Functions';
import Photo from './Components/Photo/Index';


import join_today_home from './Assets/Images/illustration_join_today_homepage.jpg';
import join_today from './Assets/Images/join_today.jpg';
//import take_a_stand from './Assets/Images/take_a_stand.jpg';
import take_a_stand from './Assets/Images/take_a_stand-version-2.jpg';


TagManager.initialize({
  gtmId:'G-5B13DZW0ZT'
});
TagManager.initialize({
  gtmId:'G-RSS6BPB0ZC'
});



function App() {

  const [photoAboveNewsletter, setPhotoAboveNewsletter] = useState(null);
  const [showStandardElements, setShowStandardElements] = useState(null);

  const ____WindowOnScroll = (e)=>{
    //console.log(e, window.scrollY, document.body.classList.contains('scrolled'));
    if(window.scrollY===0 && document.body.classList.contains('scrolled')){
      document.body.classList.remove('scrolled');
    }
    else if(window.scrollY>0 && !document.body.classList.contains('scrolled')){
      document.body.classList.add('scrolled');
    }
  }

  /*const StandardElementsAreVisible = ()=>{
    if(['/User/Login'].indexOf(window.location.pathname)!==-1)return false;
    return true;
  }*/

  const __GetPhotoSrcForAboveNewsletter = ()=>{
    if(['/'].indexOf(window.location.pathname)!==-1){
      console.log('join_today_home:', join_today_home);
      return join_today_home;
    }
    else if(['/JoinUs'].indexOf(window.location.pathname)!==-1){
      return join_today;
    }
    else if(['/Mission'].indexOf(window.location.pathname)!==-1){
      return take_a_stand;
    }
    else{
      return '';
    }
  }

  const ___OnBrowserLinkChange = ()=>{
    //console.log('StandardElementsAreVisible():', StandardElementsAreVisible());
    if(ShowStandardElements() && showStandardElements==='N'){
      setShowStandardElements('Y');
    }
    else if(!ShowStandardElements() && showStandardElements==='Y'){
      setShowStandardElements('N');
    }


    setPhotoAboveNewsletter(__GetPhotoSrcForAboveNewsletter());
  }

  const ___IllustrationAboveNewsletter_OnClick = ()=>{
    if(window.location.pathname==='/'){
      NavigateToPage('/JoinUs');
    }
  }
  const ___IllustrationAboveNewsletterAdditionalClass = ()=>{
    if(window.location.pathname==='/')return 'photo_above_newsletter';
    return '';
  }

  useEffect(()=>{

    window.addEventListener(EVENT_POPSTATE, ___OnBrowserLinkChange);
    window.addEventListener('scroll', ____WindowOnScroll);
    console.log('App.js Mount');



    ////////////////////////////////////////////////////////////////////////////
    ////Implemeting the script
    /*const script_klaviyo = document.createElement('script');
    script_klaviyo.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SbZC6L";
    script_klaviyo.async = true;
    document.body.appendChild(script_klaviyo);*/


    
    return ()=>{
      window.removeEventListener(EVENT_POPSTATE, ___OnBrowserLinkChange);
      window.addEventListener('scroll', ____WindowOnScroll);
      console.log('App.js Unmount');

      //document.body.removeChild(script_klaviyo);
    }
  }, []);

  
  if(window.location.pathname=='/Test'){
    return (
      <div>
      <Newsletter />
      </div>
    );
  }
  else if(ShowStandardElements() || showStandardElements==='Y')
    return (
      <div>
        <Header />
        <Header isForMobile={true} />
        {/*___CURRENT_PAGE()*/}
        <PagesContent />
        <LogosBox content={<div><h3>As seen on</h3></div>} />
        {photoAboveNewsletter===null && __GetPhotoSrcForAboveNewsletter()!==''?<Photo
          onClick={___IllustrationAboveNewsletter_OnClick} 
          src={__GetPhotoSrcForAboveNewsletter()} className={`${___IllustrationAboveNewsletterAdditionalClass()}`} />:null}
        {photoAboveNewsletter===null?null:<Photo 
          onClick={___IllustrationAboveNewsletter_OnClick}
          src={photoAboveNewsletter} className={`${___IllustrationAboveNewsletterAdditionalClass()}`} />}
        <Newsletter />
        <ZFooter />
      </div>
    );
  else 
    return (<div>
        <PagesContent />
      </div>);

  /*return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );*/
}

export default App;
