export default function WalletBalanceItem({
  warning,
  right_content,
  price_details
}){
  return (<div className={`wallet_balance_item ${warning!==undefined?'warning':''}`}>
    {warning!==undefined?<div className="warning_content">Undefined Private Token</div>:null}
    {price_details!==undefined?<div className="coin_content">
      <img src={price_details.coin_type} alt={price_details.coin_title} /> <strong>{price_details.coin_title}</strong>
    </div>:null}
    <div className="right_text">{right_content!==undefined?right_content:'Undefined Right Content'}</div>
  </div>);
}