import { useEffect, useState } from "react";
import BigHeaderWithContent from "../../Components/BigHeaderWithContent/Index";
import Content from "../../Components/Content/Index";
import ContentCentered2 from "../../Components/ContentCentered2/Index";
import { EVENT_POPSTATE, PHP_HUB_SERVER_LINK } from "../../Help/Constants";

export default function PageBlogSinglePost({data}){

  //console.log(data);

  const [dataState, setDataState] = useState(null);

  const DataFor = ()=>{
    if(data!==undefined){return data;}
    else{
      return dataState;
    }
  }

  const LoadThePost = ()=>{
    
    
    /*if(window.last_loaded_post_id===window.location.pathname.split(':')[1]){
      return;
    }
    window.last_loaded_post_id = window.location.pathname.split(':')[1];*/


    fetch(
      `${PHP_HUB_SERVER_LINK}hub-spot-post.php?post_id=${window.location.pathname.split(':')[1]}`)
        .then((res) => res.json())
        .then((json) => {
          console.log('json for post:', json);
          //setHubSpotPosts(json.posts);
          setDataState(json.post);
        });
  }

  //LoadThePost();
  function __EVENT_POPSTATE(){
    LoadThePost();
  }

  useEffect(()=>{

    LoadThePost();

    window.addEventListener(EVENT_POPSTATE, __EVENT_POPSTATE);

    return ()=>{
      window.removeEventListener(EVENT_POPSTATE, __EVENT_POPSTATE);
    }
  }, [])

  return (<div className="page">
  
    {
      DataFor()!==null && DataFor()!==undefined
      ?
      <BigHeaderWithContent
        margin_bottom="add_margin"
        isForPost={true}
        PostTitle={DataFor().title}
        PostPhoto={DataFor().photo}
        content={<div>
          
            <p><i>{DataFor().date}</i></p>
            <h1>{DataFor().title}</h1>
            {/*<CustomLink to="./Participate" className="btn btn-success">Join Us</CustomLink>*/}
            {
              //DataFor().contentBig
            }
            <div dangerouslySetInnerHTML={{__html: DataFor().contentBig.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div>
          </div>}
      />
      :
      null
    }

    


    <Content 
      addListBoxesPosts={true}
    />
    <ContentCentered2 /> 

  </div>);
}