import { useEffect, useState } from "react";
import Home from "./Home/Index";
import Participate from "./Participate/Index";
import { EVENT_POPSTATE } from "../Help/Constants";
import OurMission from "./OurMission/Index";
import News from "./News/Index";
import { BlogPostsData } from "../Help/BlogData";
import PageBlogSinglePost from "./PageBlogSinglePost/Index";
import Login from "./Login/Index";
import Dashboard from "./Dashboard/Home/Index";
import SignUp from "./SignUp/Index";
import ShopPrintify from "./Shop/Index";

export default function PagesContent(){


  const [page, setPage] = useState(null);
  const routes = {
    '/': <Home />,
    '/Participate': <Participate />,
    '/JoinUs': <Participate />,
    '/Explore': <Participate />,
    '/OurMission': <OurMission />,
    '/Mission': <OurMission />,
    '/News': <News />,
    '/User/Login': <Login />,
    '/User/SignUp': <SignUp />,
    '/LitePaper': <Home />,
    '/Shop': <ShopPrintify />,
    //'/Dashboard/Home': <Dashboard />,
  };
  BlogPostsData.map((data, key)=>{
    routes[data.route] = <PageBlogSinglePost data={data} />
  });
  console.log('routes:', routes);
  const ___PAGE_FROM_URL_PATH = ()=>{

    if(window.location.pathname.indexOf('/Dashboard')!==-1)
      return <Dashboard />;
    if(window.location.pathname.indexOf('hs-post-id:')!==-1)
      return <PageBlogSinglePost />;
    const the_page = routes[window.location.pathname];
    if(the_page===null || the_page===undefined)
      return <Home />;
    return the_page;
  }
  const ___CURRENT_PAGE = ()=>{
    return (page!==null?page:___PAGE_FROM_URL_PATH());
  }
  const ___OnBrowserLinkChange = (e)=>{
    setPage( ___PAGE_FROM_URL_PATH() );
    //window.scrollTo(0,0);
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }
  
  useEffect(()=>{

    window.addEventListener(EVENT_POPSTATE, ___OnBrowserLinkChange);
    console.log('PagesContent.js Mount');

    
    return ()=>{
      window.removeEventListener(EVENT_POPSTATE, ___OnBrowserLinkChange);
      console.log('PagesContent.js Unmount');
    }
  }, [])

  return (<div className="pages_content">
  {___CURRENT_PAGE()}
  </div>);
}