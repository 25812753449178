/*
This header is made on base of the header of the website
*/
import { Button, Col, Container, Row } from 'react-bootstrap';
import PDG_Logo_Black_RGB from '../../../../Assets/Images/PDG_Logo_Black_RGB.png';
import { useEffect, useState } from 'react';
//import { EVENT_POPSTATE, ON_SCROLL_TO_THE_FORM_FROM_HEADER } from '../../Help/Constants';



import CustomLink from '../../../../Components/CustomLink/Index';
import { EVENT_POPSTATE, ON_SCROLL_TO_THE_FORM_FROM_HEADER } from '../../../../Help/Constants';



export default function HeaderDashboard(){

  const [hamburgIsActive, setHamburgIsActive] = useState(false);
  //const [showMe, setShowMe] = useState(true);


  const ___ON_SCROLL_TO_THE_FORM_FROM_HEADER = ()=>{
    setHamburgIsActive(false);
    document.body.classList.remove('d_sidebar_is_visible');
  }

  const _onChangeLink = ()=>{
    console.log('Window link is changed, hamburgIsActive:', hamburgIsActive);
    if(hamburgIsActive){
      setHamburgIsActive(false);
      document.body.classList.remove('d_sidebar_is_visible');
    }
  }

  console.log('Header is reloading');
  useEffect(()=>{
    
    window.addEventListener(ON_SCROLL_TO_THE_FORM_FROM_HEADER, ___ON_SCROLL_TO_THE_FORM_FROM_HEADER);
    window.addEventListener(EVENT_POPSTATE, _onChangeLink);

    return ()=>{
      window.removeEventListener(ON_SCROLL_TO_THE_FORM_FROM_HEADER, ___ON_SCROLL_TO_THE_FORM_FROM_HEADER);
      window.removeEventListener(EVENT_POPSTATE, _onChangeLink);
    }
  }, [hamburgIsActive])


  return (<header className={`is_for_mobile ${hamburgIsActive?'make_header_for_active_hamburg':''}`}>
    <Container>
      <Row>
        <Col lg={12}>

        

          <div className='header_left_menu_set'>
            <CustomLink to="/Dashboard/Home" className='header_main_logo header_main_logo_left'>
              <img src={PDG_Logo_Black_RGB} alt="Liberty" />
            </CustomLink>
            
            <Button variant='warning' className={`hamburg_button ${hamburgIsActive?'clicked':''}`} onClick={()=>{
              setHamburgIsActive(!hamburgIsActive);

              if(!hamburgIsActive===true){
                document.body.classList.add('d_sidebar_is_visible');
              }
              else{
                document.body.classList.remove('d_sidebar_is_visible');
              }

            }}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </Button>
          </div>

          

        </Col>
      </Row>
    </Container>
  </header>);
}