import { Col, Container, Row } from "react-bootstrap";

import yahoo from '../../Assets/Images/logos/logo-yahoo.png';
import news from '../../Assets/Images/logos/logo-fox-news.png';
import ratings from '../../Assets/Images/logos/logo-bank-ratings.png';
import business from '../../Assets/Images/logos/logo-fox-business.png';
import aol from '../../Assets/Images/logos/logo-aol.png';
import msn from '../../Assets/Images/logos/logo-msn.png';
import bitcoin from '../../Assets/Images/logos/logo-bitcoin.png';
import wfin from '../../Assets/Images/logos/logo-wfin.png';
import korea_times from '../../Assets/Images/logos/logo-korea-times.png';
import nasta from '../../Assets/Images/logos/logo-nasta.png';
import wtam from '../../Assets/Images/logos/logo-wtam.png';
import bloomberg from '../../Assets/Images/logos/logo-bloomberg.png';
import benzinga from '../../Assets/Images/logos/logo-benzinga.png';
import ny_sun from '../../Assets/Images/logos/logo-ny-sun.png';
import fintech_times from '../../Assets/Images/logos/logo-fintech-times.png';
import logo_nasdaq from '../../Assets/Images/logos/2560px-NASDAQ_Logo.svg.png';
import logo_fox from '../../Assets/Images/logos/FOX_wordmark.svg.png';


export default function LogosBox({content}){

  const the_logos = [
    [yahoo, 'yeahoo'],
    [ratings, 'ratings'],
    [business, 'business'],
    [aol, 'aol'],
    [msn, 'msn'],
    [bitcoin, 'bitcoin'],
    [wfin, 'wfin'],
    [korea_times, 'korea_times'],
    [wtam, 'wtam'],
    [bloomberg, 'bloomberg'],
    [benzinga, 'benzinga'],
    [ny_sun, 'ny_sun'],
    [fintech_times, 'fintech_times'],
    [logo_nasdaq, 'nasdaq'],
    [logo_fox, 'fox'],
  ];

  return (<section className="logos_box">
    <Container>
      <Row>
        <Col>
          <div className="logox_box_content">
            <div className="content">{content}</div>
            <div className="the_logos">{
              the_logos.map((logo_data, key)=>{
                return <img key={`logo-key-${key}`} src={logo_data[0]} alt={logo_data[1]} />
              })
            }</div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>);
}