import { Col, Container, Row } from "react-bootstrap";
import tv from '../../Assets/Images/TV-2.png';
import CustomLink from "../CustomLink/Index";

export default function ContentTVCircle({
  titleSmaller,
  title,
  content,
}){
  return (<section className={`content_tv_circle ${titleSmaller===true?'title_smaller':''}`}>
    <Container>
      <Row>
        <Col lg={12}>
          {/*<h2>The Liberty<br/>Blockchain Platform</h2>*/}
          <h2>{title}</h2>
          <div className={`circle_content ${(content===undefined || content===null?'just_tv':'')}`}>
            <div className="the_tv">
              <img src={tv} alt="The TV" />
            </div>
            {
              content!==undefined && content!==null?<div className="content">
                {content}
              </div>:null
            }
            {}
          </div>
        </Col>
      </Row>
    </Container>
  </section>);
}