import { Col, Container, Row } from "react-bootstrap";
import CustomLink from "../CustomLink/Index";
import { OriginDomain } from "../../Help/Functions";

export default function X3YellowBoxes({panelsContent, marginTop}){
  
  const yellow_panels_content = panelsContent;



  return (<section className={`x3_yellow_boxes ${(marginTop!==undefined && marginTop!==null?marginTop:'')}`}>
    <Container>
      <Row>
        <Col lg={12}>
          <ul className="list_yellow_boxes">
            {yellow_panels_content.map((details, key)=>{
              return <li key={`list_yellow_box_${key}`}>
                <span className="logo">
                  <span className="logo_background"></span>
                  <img src={details.icon} alt="Logo" />
                </span>
                {details.content}
              </li>;
            })}
          </ul>

          <div className="our_missing_buttons">
            <CustomLink to="/Mission" className="btn btn-success">Our mission</CustomLink>
          </div>
        </Col>
      </Row>
    </Container>
  </section>);
}