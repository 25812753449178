import { EVENT_POPSTATE } from "./Constants";

export function NavigateToPage(to){
  window.history.pushState({}, "", `${window.location.origin}${to}`);    
  const navEvent = new PopStateEvent(EVENT_POPSTATE);
  window.dispatchEvent(navEvent);
}


export function OriginDomain(){
  return document.location.origin;
}


export function ShowStandardElements(){
  if(['/User/Login', '/User/SignUp'].indexOf(window.location.pathname)!==-1)return false;
  if(window.location.pathname.indexOf('/Dashboard')!==-1)return false;
  return true;
}


export function ScrollTo(id){
  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}