//import InputMask from "react-input-mask";

import usd_coin from '../../../../../Assets/Images/icon_usd_coin.png';
import icon_info from '../../../../../Assets/Images/icon-d-info.png';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DInputNumberWithSuffix from "../../../Components/DInputNumberWithSuffix/Index";


import illustration_wallet from '../../../../../Assets/Images/wallet-illustration.png';


export default function DashboardWallet(){

  /*const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
  const letter = /(?!.*[DFIOQU])[A-Z]/i;
  const digit = /[0-9]/;
  const number = /^[0-9]{1,6}/;
  const mask = [number, " USDC"];*/

  return (<div>
      
      <img src={illustration_wallet} alt="Illustration Wallet" className="d_illustration" />

      <Container>
        <Row>
          <Col lg={12}>
            <div className="wallet_widget">
              <div className="wallet_widget_header">
                <h1><img src={usd_coin} alt="USD Coin" /> USD Coin</h1>
                <ul>
                  <li>
                    <a href="/" onClick={(e)=>{e.preventDefault()}} className="active">Overview</a>
                  </li>
                  <li>
                    <a href="/" onClick={(e)=>{e.preventDefault()}}>Transaction History</a>
                  </li>
                </ul>
              </div>

              <div className="walled_widget_actions">
                <div className="walled_widget_x2_buttons">
                  <a href="/" className="btn_walled_send_receive send" onClick={(e)=>{e.preventDefault()}}>
                    Send
                  </a>
                  <a href="/" className="btn_walled_send_receive receive" onClick={(e)=>{e.preventDefault()}}>
                    Receive
                  </a>
                </div>
                <div className='content'>
                  <h3>Send to Another Wallet</h3>

                  <div className='item_prices_holder'>
                    <div className='item_price'>
                      <h5><strong>Available Balance</strong></h5>
                      <p>$123415.00005  / 1234 USDC</p>
                    </div>

                    <div className='item_price'>
                      <h5>Estimated Gas Fee <a href="/" className='item_price_info'><img src={icon_info} alt="Estimated Gas Fee" /></a></h5>
                      <p>$15.05  / 1.25 USDC</p>
                    </div>
                  </div>

                  <Form className=''>
                    <Form.Group controlId="USDC_Amount" className='d_group'>
                      <Form.Label>USDC Amount</Form.Label>
                      {/*<Form.Control type="text" placeholder="0.0 USDC" />*/}
                      <DInputNumberWithSuffix />
                      {/*
                        <InputMask mask={mask} className="form-control" placeholder="0.0 USDC" />
                      */}
                    </Form.Group>
                    <Form.Group controlId="SendToAddress" className='d_group'>
                      <Form.Label>Send to Address</Form.Label>
                      <Form.Control type="email" placeholder="Enter Address" />
                    </Form.Group>

                    <div className='d_form_buttons'>
                      <Button variant='light' className='btn-sm'>Clear</Button>
                      <Button variant='primary' className="btn-sm">Continue</Button>
                    </div>

                  </Form>

                </div>
              </div>

            </div>
      
          </Col>
        </Row>
      </Container>
    </div>);
}