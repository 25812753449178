import { Col, Container, Row } from "react-bootstrap";
import WalletBalanceItem from "../../../Components/WalletBalanceItem/Index";


import usd_coin from '../../../../../Assets/Images/icon-valute-usd.png';
import etherium from '../../../../../Assets/Images/icon-etherium-coin.png';
import bitcoin from '../../../../../Assets/Images/icon-bitcoin.png';

export default function DashboardWalletBalances(){
  return (<div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="dashboard_content_panel light">
              <h1>My Liberty Wallet</h1>

              <div className="wallet_balances">
                <h4>Balances</h4>

                <div className="items">
                  <WalletBalanceItem warning="Undefined Private Token" right_content="--- LBRT-P" />
                  <WalletBalanceItem warning="LBRT" right_content="--- LBRT" />
                  <WalletBalanceItem price_details={{coin_type:usd_coin, coin_title:'USD Coin'}} right_content={<div><p>$123.45</p><p>123415.00005</p></div>} />
                  <WalletBalanceItem price_details={{coin_type:etherium, coin_title:'Etherum'}} right_content={<div><p>$123.45</p><p>123415.00005</p></div>} />
                  <WalletBalanceItem price_details={{coin_type:bitcoin, coin_title:'Bitcoin'}} right_content={<div><p>$123.45</p><p>123415.00005</p></div>} />
                </div>

              </div>

            </div>
          </Col>
        </Row>
      </Container>
    </div>);
}