import { Container, Row, Col } from 'react-bootstrap';
import big_header_logo_text from '../../Assets/Images/big-header-logo-text.png';
import CustomLink from '../CustomLink/Index';
import people_watch_on_the_stars from '../../Assets/Images/the-people-look-in-the-stars.jpg';
import { OriginDomain } from '../../Help/Functions';

export default function BigHeaderWithBanner(){
  
  return (
    <section className="big_header_with_banner">
      <div className='big_header_banner'>
        <img src={people_watch_on_the_stars} alt="Liberty" />
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="content">
              {/*<img src={big_header_logo_text} alt="Liberty" />*/}
              <h1 className='special_header'>
                <i>Explore</i>
                <strong>Freedom's</strong>
                frontier
              </h1>
              {/*<CustomLink to="./Participate" className="btn btn-success">Participate</CustomLink>*/}
              <CustomLink to="/JoinUs" className="btn btn-success">UNLOCK YOUR FUTURE</CustomLink>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}