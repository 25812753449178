/*
Participate = Join Us
*/
import BigHeaderWithContent from "../../Components/BigHeaderWithContent/Index";
import Content from "../../Components/Content/Index";
import ContentCentered2 from "../../Components/ContentCentered2/Index";
import ContentTVCircle from "../../Components/ContentTVCircle/Index";
import CustomLink from "../../Components/CustomLink/Index";
import ZFooter from "../../Components/Footer/Index";
import Header from "../../Components/Header/Index";

import icon_1 from '../../Assets/Images/icon-1.png';
import icon_2 from '../../Assets/Images/icon-2.png';
import icon_3 from '../../Assets/Images/icon-3.png';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { OriginDomain, ScrollTo } from "../../Help/Functions";

export default function Participate(){

  console.log('Participate is creating...');


  return (<div className="page">
    <BigHeaderWithContent
      illustration="the_green_planet"
      content={<div>
          <h1>We want pioneers to build a free and open society on the internet</h1>
          {/*<CustomLink to={`${OriginDomain()}/JoinUs`} className="btn btn-success">Join Us</CustomLink>*/}

          <Button variant='success' onClick={()=>{
            //document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' });
            ScrollTo('newsletter');
          }}>Join Us</Button>

        </div>}
    />
    {/*<ContentTVCircle
        titleSmaller={true}
        title={<span>Join our community<br />
          and earn digital rewards<br />
          redeemable for NFTs, real world<br />
          experiences and more<br />
          as you stand with Liberty.</span>}
    />*/}
    <ContentTVCircle
        titleSmaller={true}
        title={<span>Join our community<br />
          AND EARN DIGITAL REWARDS<br />
          REDEEMABLE FOR NFTS, REAL WORLD<br />
          EXPERIENCES AND MORE<br />
          AS YOU STAND WITH LIBERTY</span>}
    />

    




    <Content 
      margin_bottom="mb-0"
      content_top={<div>
        <h3>3 Ways To Join</h3>
        </div>}
        iconsContent = {[
          {
            icon:icon_1,
            content: <span><p>Sign up for our free newsletter to get the latest news about why blockchain and Web 3 are important to personal sovereignty. Subscribers also receive exclusive offers, along with news and information you can use to protect your and your family’s privacy.</p>
            {/*<CustomLink className="btn btn-danger disabled" to="./SignUp">Sign Up</CustomLink>*/}
            <Button variant="danger" onClick={()=>{
              //document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' });
              ScrollTo('newsletter');
            }}>Sign Up</Button>
            </span>
          },
          /*{
            icon:icon_2,
            content: <span><p>If you are curious about how Web 3 works and want to learn how to operate the infrastructure of the blockchain while earning a small amount digital rewards redeemable for NFTs and experiences, the Liberty Lite Node is the place to start.</p>
            <CustomLink className="btn btn-success disabled" to="/BuyALightNode">BUY A NODE</CustomLink>
            </span>
          },*/
          /*{
            icon:icon_2,
            content: <span><p>Nodes are the backbone of the Liberty blockchain. Earn maximum rewards and get exclusive experiences and information only available to node owners.</p>
            <CustomLink className="btn btn-primary disabled" to="/BuyASmartNode">BUY A SMART NODE</CustomLink>
            </span>
          },*/
          {
            icon:icon_2,
            content: <span><p>Be a pioneer on the new digital frontier with a Smart Node. Smart Nodes are the backbone of the Liberty blockchain. Earn maximum digital rewards redeemable for NFTs and experiences along with exclusive opportunities and content only available to node owners.</p>
              {/*<CustomLink className="btn btn-warning" to="/BuyASmartNode">BUY A SMART NODE</CustomLink>*/}
              {/*<OverlayTrigger 
                placement="top"
                transition={false}
                overlay={
                  <Tooltip>
                    COMING SOON
                  </Tooltip>
                }
                >
                <Button variant="warning" onClick={()=>{ ScrollTo('newsletter') }}>BUY A SMART NODE</Button>
              </OverlayTrigger>*/}
              <Button variant="warning" onClick={()=>{ window.location.href='https://app.libertyblockchain.com/signup'; }}>BUY A SMART NODE</Button>
            </span>
          },
          {
            icon:icon_3,
            content: <span><p>Curious and not sure where to begin? If you fear missing out and want to learn how to operate the infrastructure of the blockchain while earning limited digital rewards redeemable for NFTs and experiences, the Liberty Lite Node is the place to start.</p>
              {/*<CustomLink className="btn btn-warning" to="/BuyALightNode">BUY A NODE</CustomLink>*/}
              {/*<OverlayTrigger 
                placement="top"
                transition={false}
                overlay={
                  <Tooltip>
                    COMING SOON
                  </Tooltip>
                }
                >
                <Button variant="warning" onClick={()=>{ ScrollTo('newsletter') }}>BUY A NODE</Button>
              </OverlayTrigger>*/}
              <Button variant="warning" onClick={()=>{ window.location.href='https://app.libertyblockchain.com/signup'; }}>BUY A NODE</Button>
            </span>
          },
        ]}
      />
    <ContentCentered2 />
  </div>);
}