import the_man_that_look from '../Assets/Images/x3-the-man-that-look.jpg';
//import the_beach from '../Assets/Images/x3-wow-the-beach.jpg';
import the_couple from '../Assets/Images/x3-the-lovely-couple-work-together.jpg';
import blog_3_post from '../Assets/Images/blog_3_post.png';

export const BlogPostsData = [
  {
    route:'/PioneerDevelopmentGroupAndLibertyBlockchain',
    photo:null,
    date:'June 15, 2023',
    title:'Pioneer Development Group and Liberty Blockchain',
    content:<span><h4>Pioneer Development Group and Liberty Blockchain</h4></span>,
    contentBig:<div>
      <p>Members of the Liberty Blockchain community may have noticed a recent change to the website and a new brand as well. Pioneer Development Group (PDG) is the creator of the Liberty Blockchain and we are now identified as such on the website and in our communications. PDG has a number of important announcements coming soon so check your emails, social media, and here at the Liberty Star Dispatch for important news as we get ready to launch our blockchain in the very near future.</p>
    </div>,
  },
  {
    route:'/AnnouncingTheNewLibertyStarDispatch',
    photo:null,
    date:'June 15, 2023',
    title:'Announcing the new Liberty Star Dispatch',
    content:<span><h4>Announcing the new Liberty Star Dispatch</h4></span>,
    contentBig:<div>
      <p>Enjoy articles by leading blockchain experts and news you can use to protect your privacy and follow Pioneer Development Group as we build the internet of tomorrow. Be a pioneer for the new digital frontier and join us in building an internet and applications you can be proud of and rely on.</p>
    </div>,
  },
  {
    route:'/PioneerDevelopmentGroupsLibertyBlockchainOnNeilCavuto',
    photo:blog_3_post,
    date:'June 15, 2023',
    title:'Pioneer Development Groups’ Liberty Blockchain on Neil Cavuto',
    content:<span><h4>Pioneer Development Groups’ Liberty Blockchain on Neil Cavuto</h4></span>,
    contentBig:<div>
      <p>Pioneer Development Group’s Christopher Alexander speak with Neil Cavuto about Silvergate Bank’s market exit, SVB and the importance of exchanges like Coinbase.</p>
    </div>,
  },
]