import { Button, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap';
import rockets from '../../Assets/Images/newsletter-rockets.png';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';


//https://github.com/catamphetamine/react-phone-number-input
import PhoneInput from 'react-phone-number-input'


import { useState } from 'react';

export default function Newsletter(){

  const [disabledSubscribedBtn, setDisabledSubscribedBtn] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const ___SubscribeTheUser = ()=>{
    console.log('newsletter_email:', document.getElementById('newsletter_email').value);

    if(!isValidEmail(document.getElementById('newsletter_email').value)){
      setErrorMessage(<p className='text-danger mt-5'>Please enter valid email address, {`The Email `}<strong> - {document.getElementById('newsletter_email').value} - </strong> is not valid email address.</p>);
      return;
    }

    setDisabledSubscribedBtn(' ...');

    /*fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache"
      },
      data: {
        //g: "Y4K7q2",
        g: "RXc4DU",
        email: document.getElementById('newsletter_email').value
        // $source: "Custom Form"
      }
    });*/

    const data = {
      g: 'RW7R4A',
      //g: 'RR8fV2',
      email: document.getElementById('newsletter_email').value,
      phone_number: document.getElementById('newsletter_phone').value,
      /*email: email ?? '',
      $fields: '$first_name,$last_name',
      $first_name: firstName ?? '',
      $last_name: lastName ?? '',*/
    };
    const urlData = new URLSearchParams(data)
    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      method: 'POST',
      body: urlData,
    }).then(response => response.json())
    .then(data => {
      console.log('data:', data);
      setDisabledSubscribedBtn('');
      if(data.data.is_subscribed===true){
        setErrorMessage(<p className='text-danger mt-5'>{`User With Email `}<strong>{document.getElementById('newsletter_email').value}</strong> is already subscribed.</p>);
      }
      else{
        setSuccessMessage(<p className='text-success mt-5'>{`User With Email `}<strong>{document.getElementById('newsletter_email').value}</strong> and Phone Number {document.getElementById('newsletter_phone').value} is subscribed.</p>);
      }
    });
  }

  return (<section className="newsletter" id="newsletter">
  
    <div className='banner_holder'>
      <img src={rockets} alt="Newsletter Rockets" />
    </div>
    <div className='content'>
      <Container>
        <Row>
          <Col lg={12}>
            <div className='newsletter_content'>
              <div className='html_content'>
                <h5>Newsletter</h5>
                <h3>STay in the loop</h3>
                <p>Get the latest updates on our mission delivered right to<br />your inbox.</p>
                {successMessage}
              </div>
              {

                successMessage===null?
                <form>

                
                  <InputGroup>
                    <PhoneInput 
                      id="newsletter_phone"
                      className='form-control'
                      international={true}
                      defaultCountry="US"
                      onChange={()=>{}} />
                  </InputGroup>
                  {/*<InputGroup>
                  
                    <DropdownButton
                      variant="outline-secondary"
                      title="Dropdown"
                      id="input-group-dropdown-1"
                    >
                      <Dropdown.Item href="#">Action</Dropdown.Item>
                      <Dropdown.Item href="#">Another action</Dropdown.Item>
                      <Dropdown.Item href="#">Something else here</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Separated link</Dropdown.Item>
                    </DropdownButton>

                    <Form.Control id="newsletter_phone"
                      placeholder="Phone Number" type="text"
                    />
                  </InputGroup>*/}
                  <InputGroup>
                    <Form.Control id="newsletter_email"
                      placeholder="Email" type="email"
                    />
                  </InputGroup>
                  <Button variant="primary" onClick={___SubscribeTheUser} disabled={disabledSubscribedBtn===''?false:true}>
                    Subscribe{disabledSubscribedBtn}
                  </Button>
                  {errorMessage}
                </form>
                :
                null

              }
              
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>);
}