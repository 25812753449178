import CustomLink from "../../../../Components/CustomLink/Index";
import icon_info from '../../../../Assets/Images/icon-d-info.png';

export default function DashboardPanelCount({
  title,
  count,
  status,
  link,
  info,
}){
  return (<div className="dashboard_panel_count">
    <h4>{title!==undefined?title:'Lite Nodes'}</h4>
    <div className="count">
      {count!==undefined?count:0}
      <small>{status!==undefined?status:'Online'}</small>
    </div>
    {link!==undefined?<CustomLink to={link.to}>{link.title}</CustomLink>:null}
    {info!==undefined?<img className="d_icon_info" src={icon_info} alt="Info" />:null}

  </div>);
}