export const EVENT_POPSTATE = 'popstate';
export const ON_MOBILE_MENU_OPEN = 'on-mobile-menu-open';
export const ON_MOBILE_MENU_CLOSED = 'on-mobile-menu-closed';
export const ON_SCROLL_TO_THE_FORM_FROM_HEADER = 'ON_SCROLL_TO_THE_FORM_FROM_HEADER';

export const ON_OPEN_PAGES_THAT_HAVE_NOT_STANDARD_ELEMENTS = 'ON_OPEN_PAGES_THAT_HAVE_NOT_STANDARD_ELEMENTS';


export const PHP_HUB_SERVER_LINK = (
  window.location.href.indexOf('localhost:3000')!==-1
  ?
  'http://[::1]/projects/ThomasNiemczewski/liberty/react/php-server/'
  :
  'https://pioneerdevgrp.com/php-server/');