import CustomLink from '../../Components/CustomLink/Index';


import illustration_signup from '../../Assets/Images/illustration-signup.jpg';
import recaptcha from '../../Assets/Images/recaptcha.png';

import the_logo from '../../Assets/Images/logo-liberty-signup.png';
import { Button, Form } from 'react-bootstrap';
import ZCheckbox from '../../Components/Forms/ZCheckbox/Index';



export default function SignUp(){
  return (<div className="page page_signup">
    <img src={illustration_signup} alt="Illustration Refer A Friend" className="d_illustration d_illustration_signup" />



    <div className='signup_form'>
      <Form className='signup_form_inner_content'>
        <CustomLink to="/" className="signup_logo">
          <img src={the_logo} alt="Sign up" />
        </CustomLink>

        <div className='signup_form_intro_content'>
          <h1>Create Your Account</h1>
          <p>Already have an account? <CustomLink to="/User/Login">Login here</CustomLink></p>
        </div>

        <Form.Group className="signup_form_group" controlId="first_name">
          <Form.Control type="text" placeholder="First Name" name='first_name' />
        </Form.Group>
        <Form.Group className="signup_form_group" controlId="last_name">
          <Form.Control type="text" placeholder="Last Name" name='last_name' />
        </Form.Group>
        <Form.Group className="signup_form_group" controlId="email_address">
          <Form.Control type="text" placeholder="Email Address" name='email_address' />
        </Form.Group>
        <Form.Group className="signup_form_group" controlId="password">
          <Form.Control type="password" placeholder="Create Password" name='password' />
        </Form.Group>
        <Form.Group className="signup_form_group" controlId="confirm_password">
          <Form.Control type="password" placeholder="Confirm Password" name='confirm_password' />
        </Form.Group>

        <div className='signup_checkboxes'>
          <Form.Group>
            <ZCheckbox checked={true} />
          </Form.Group>
          <Form.Group>
            <ZCheckbox />
          </Form.Group>
        </div>

        <div className='signup_recaptcha'>
          <img src={recaptcha} alt="Google Recaptcha" />
        </div>


        <div className='signup_buttons_holder'>
          <Button variant='primary'>Get Started</Button>
        </div>

      </Form>
    </div>

  </div>);
}