import { NavigateToPage } from "../../../Help/Functions";
import DashboardSidebarMenu from "../Components/SidebarMenu/Index";
import DashboardHome from "./Content/DashboardHome";
import DashboardNodes from "./Content/Dashboard/DashboardNodes";
import DashboardRewards from "./Content/Dashboard/DashboardRewards";
import DashboardWallet from "./Content/Dashboard/DashboardWallet";
import DashboardReferAFriend from "./Content/DashboardReferAFriends";
import DashboardVoting from "./Content/DashboardVoting";
import DashboardSupport from "./Content/DashboardSupport";
import DashboardStore from "./Content/DashboardStore";
import DashboardCart from "./Content/DashboardCart";
import DashboardWalletBalances from "./Content/Dashboard/DashboardWalletBalances";
import HeaderDashboard from "../Components/Header/Index";

export default function Dashboard(){

  /*if(1===1){
    //if not logged in, go back to the login page
    NavigateToPage('/User/Login');
  }*/

  console.log('Dashboard content holder is builded');

  const routes = {
    '/Dashboard/Home': <DashboardHome />,
    '/Dashboard/Nodes': <DashboardNodes />,
    '/Dashboard/Rewards': <DashboardRewards />,
    '/Dashboard/Wallet': <DashboardWallet />,
    '/Dashboard/WalletBalances': <DashboardWalletBalances />,
    '/Dashboard/ReferAFriend': <DashboardReferAFriend />,
    '/Dashboard/Voting': <DashboardVoting />,
    '/Dashboard/Support': <DashboardSupport />,
    '/Dashboard/Store': <DashboardStore />,
    '/Dashboard/Cart': <DashboardCart />,
  };

  const getContent = ()=>{
    const content = routes[window.location.pathname];

    return content;
  }

  return (<div className="page dashboard">
    <HeaderDashboard />
    <DashboardSidebarMenu />
    <div className="dashboard_content_area">
      {getContent()}
    </div>
  </div>);
}