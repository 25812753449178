import { Container, Row, Col, Button } from "react-bootstrap";
import CustomLink from "../CustomLink/Index";
import banner_circle_left from '../../Assets/Images/banner-circle-red-left-small.png';
import banner_circle_left_2 from '../../Assets/Images/banner-circle-red-small-2.png';
import banner_circle_right from '../../Assets/Images/banner-circle-yellow.png';
import banner_cirlce_big_red from '../../Assets/Images/banner-circle-red.png';
import banner_zraci from '../../Assets/Images/banner-zraci.png';
import { OriginDomain, ScrollTo } from "../../Help/Functions";
import { ON_SCROLL_TO_THE_FORM_FROM_HEADER } from "../../Help/Constants";


export default function LibertyBanner({content}){


  return (
    <section className="liberty_banner">

      <img src={banner_circle_left} alt="Liberty Banner" className="left_circle" />
      <img src={banner_circle_left_2} alt="Liberty Banner" className="left_circle_small" />
      <img src={banner_circle_right} alt="Liberty Banner" className="right_big_circle" />
      <img src={banner_cirlce_big_red} alt="Liberty Banner" className="center_big_red_circle" />

      <Container>
        <Row>
          <Col lg={12}>
            <div className="liberty_banner_content">
              <img src={banner_zraci} alt="Liberty Banner Zraci" className="zraci" />
              {/*<h2>Liberty is taking<br/>a stand for<br/>freeDom and openness</h2>*/}
              {/*<h2>PIoneer development GROUP<br />is taking a stand for Liberty </h2>*/}
              {(content!==undefined && content!==null?content:'Please add content')}
              
              <div className="our_missing_buttons">
                {/*<CustomLink className='btn btn-success' to={`${OriginDomain()}/JoinUs`}>Join Today</CustomLink>*/}
                <Button className="btn btn-success" onClick={()=>{
                  
                  //document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' });
                  ScrollTo('newsletter');
                  //___OnBrowserLinkChange();
                  window.dispatchEvent(new CustomEvent(ON_SCROLL_TO_THE_FORM_FROM_HEADER));
                }}>Join Today</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}