import { Col, Container, Row } from "react-bootstrap";
import tv from '../../Assets/Images/TV.png';
import icon_satelite_antena from '../../Assets/Images/icon-satelite-antena.png';
import icon_chart from '../../Assets/Images/icon-chart.png';
import icon_rocket from '../../Assets/Images/icon-rocket.png';
import CustomLink from "../CustomLink/Index";

export default function ContentPhotoHomeUpdate({
  contentFullWidth, 
  contentCentered, 
  /*panelsContent,*/ 
  content,
  marginBottom,
  paddingBottom
}){

  //console.log('panelsContent:', panelsContent);

  /*const yellow_panels_content = [
    {
      icon:icon_satelite_antena,
      content:<span><h4>WEB 3 Architecture</h4><p>Decentralized technology to ensure everyone is given a fair chance to compete in the marketplace of ideas.</p></span>,
    },
    {
      icon:icon_chart,
      content:<span><h4>EARN REWARDS</h4><p>Earn exclusive rewards and help shape what that future will look like.</p></span>,
    },
    {
      icon:icon_rocket,
      content:<span><h4>FOunded By Veterans</h4><p>Celebrate and support the people, organizations, and principles that stand and have stood for freedom around the world. </p></span>,
    },
  ];*/
  //const yellow_panels_content = panelsContent;


  return (
    <section className={`content_photo ${(marginBottom!==undefined && marginBottom!==null?marginBottom:'default-bottom')} ${(paddingBottom!==undefined && paddingBottom!==null?paddingBottom:'')}`}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="inner_content">
              {/*<div className="content">
                <h5>NODE SOFTWARE</h5>
                <h2>Liberty Provides a free and open society using Web 3</h2>
                <p>By joining Liberty and operating a blockchain node, you join a community and Web3 platform focused on the principles of a free and open society. Node ownership plays a critical role in providing the necessary infrastructure for the Liberty community and from which future Liberty utility services will run to provide security and privacy for digital communications.</p>
              </div>
              <img src={tv} alt="TV" />*/}
              <div className={`content ${contentFullWidth===true?'content_full_width':''} ${contentCentered===true?'centered_text':''}`} >
                {}
                {(content!==undefined && content!=null?content: 'Please add content')}
              </div>
            </div>
          </Col>
        </Row>
        {/*
        <Row className="minus_margin">
          <Col lg={12}>
            
            
            <ul className="list_yellow_boxes">
              {yellow_panels_content.map((details, key)=>{
                return <li key={`list_yellow_box_${key}`}>
                  <span className="logo">
                    <span className="logo_background"></span>
                    <img src={details.icon} alt="Logo" />
                  </span>
                  {details.content}
                </li>;
              })}
            </ul>

            <div className="our_missing_buttons">
              <CustomLink to="./Mission" className="btn btn-success">Our mission</CustomLink>
            </div>

          </Col>
        </Row>
            */ }
      </Container>
    </section>
  );
}