/*import the_man_that_look from '../../Assets/Images/x3-the-man-that-look.jpg';
import the_beach from '../../Assets/Images/x3-wow-the-beach.jpg';
import the_couple from '../../Assets/Images/x3-the-lovely-couple-work-together.jpg';*/

import { BlogPostsData } from "../../Help/BlogData";
import { NavigateToPage, OriginDomain } from "../../Help/Functions";
import livberty_logo from '../../Assets/Images/livberty_logo.png';
import { useEffect, useState } from "react";
import { PHP_HUB_SERVER_LINK } from "../../Help/Constants";


export default function ListBoxesPosts({showAllPosts}){

  const [hubspotPosts, setHubSpotPosts] = useState([]);

  useEffect(()=>{

    //fetch()

    console.log('Here fetching should happen');

    
      fetch(
        `${PHP_HUB_SERVER_LINK}hub-spot-posts.php?show-all=${showAllPosts===true?'Y':'N'}`)
                  .then((res) => res.json())
                  .then((json) => {
                    console.log('json:', json);
                    setHubSpotPosts(json.posts);
                  });


    return ()=>{}
  }, [])

  return (
    <ul className="list_yellow_boxes list_box_posts">
    {
      //BlogPostsData.map((details, key)=>{
        hubspotPosts.map((details, key)=>{
        return <li key={`liar_box_post_${key}`} onClick={()=>{
          //Here function for redirecting to the posts
          NavigateToPage(`${details.route}`)
        }}>
          {(details.photo===null?null:<img src={details.photo} alt="Cover" />)}
          <span className="date">{details.date}</span>
          {
            //details.content
          }
          <span><h4>{details.content}</h4></span>
        </li>;
      })
    }
    </ul>
  );
}