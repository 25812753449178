import { useEffect } from "react";
import { Form } from "react-bootstrap";

import icon_arrows_up_and_down from '../../../../Assets/Images/icon-arrows-up-and-down-black.png';

export default function DInputNumberWithSuffix(){

  let timeout_after_change_input;

  const _inputOnChange = (e)=>{
    if(e.target.value.indexOf(' USDC')!==-1){
      e.target.value = e.target.value.split(' USDC')[0];
    }
  }

  useEffect(()=>{
    return ()=>{
      clearTimeout(timeout_after_change_input);
    }
  },[]);

  return <div className="d_number_suffix">
    <Form.Control type="text" placeholder="0.0 USDC" 
    onKeyDown={_inputOnChange}
    onFocus={_inputOnChange}
    onBlur={(e)=>{
      if(e.target.value.indexOf(' USDC')===-1){
        e.target.value = `${e.target.value} USDC`;
      }
    }} />
    <div className="right_controls">
      <div className="arrows">
        <img src={icon_arrows_up_and_down} alt="Arrows" />
      </div>
      <div className="label">max</div>
    </div>
  </div>
}