import { Col, Container, Row } from "react-bootstrap";
/*import the_man_that_look from '../../Assets/Images/x3-the-man-that-look.jpg';
import the_beach from '../../Assets/Images/x3-wow-the-beach.jpg';
import the_couple from '../../Assets/Images/x3-the-lovely-couple-work-together.jpg';*/
import CustomLink from "../CustomLink/Index";
import ListBoxesPosts from "../ListBoxesPosts/Index";
import { OriginDomain } from "../../Help/Functions";

export default function ContentCentered({content}){

  /*const x3_posts_content = [
    {
      photo:the_man_that_look,
      date:'April 18, 2023',
      content:<span><h4>Duis consectetur nisi non nibh eget blandit diam consequat.</h4></span>,
    },
    {
      photo:the_beach,
      date:'April 22, 2023',
      content:<span><h4>In convallis tellus non facilisis pellentesque. Nam quis elit a lectus</h4></span>,
    },
    {
      photo:the_couple,
      date:'April 30, 2023',
      content:<span><h4>Donec cursus tristique dapibus. Duis ac ultricies purus.</h4></span>,
    },
  ];*/

  return (<section className="content_centered">
    <Container>
      <Row>
        <Col>
          <div className="content">
            {(content!==undefined && content!==null?content:'Please add content')}
          </div>

          {/*<ul className="list_yellow_boxes list_box_posts">
          {
            x3_posts_content.map((details, key)=>{
              return <li key={`liar_box_post_${key}`} onClick={()=>{
                //Here function for redirecting to the posts
              }}>
                <img src={details.photo} alt="Cover" />
                <span className="date">{details.date}</span>
                {details.content}
              </li>;
            })
          }
          </ul>*/}
          <ListBoxesPosts />


          <div className="our_missing_buttons">
            <CustomLink className="btn btn-success" to="/News">SEE ALL POSTS</CustomLink>
          </div>

        </Col>
      </Row>
    </Container>
  </section>);
}